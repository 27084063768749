export default {
  name: 'CustomLinkEditor',

  props: {
    socialLink: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      title: null,
      link: null,
    };
  },

  created() {
    this.title = this.socialLink.title;
    this.link = this.socialLink.link;
  },

  watch: {
    title() {
      this.emitChange();
    },

    link() {
      this.emitChange();
    },
  },

  methods: {
    onClickRemove() {
      this.$emit('remove');
    },

    emitChange() {
      this.$emit('change', {
        title: this.title,
        link: this.link,
      });
    },
  },
};
