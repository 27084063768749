function search(selector) {
  return new Promise((resolve) => {
    window[`__${selector}`]++;
    setTimeout(resolve, window[`__${selector}__delay`]);
  });
}

export default function waitForElement(selector, delay = 10000, tries = 2) {
  const element = document.querySelector(selector);

  if (!window[`__${selector}`]) {
    window[`__${selector}`] = 0;
    window[`__${selector}__delay`] = delay;
    window[`__${selector}__tries`] = tries;
  }

  if (element === null) {
    if (window[`__${selector}`] >= window[`__${selector}__tries`]) {
      window[`__${selector}`] = 0;
      return Promise.resolve(null);
    }

    return search(selector).then(() => waitForElement(selector));
  } else {
    return Promise.resolve(element);
  }
}
