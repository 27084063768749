import MainLayout from '@/layouts/MainLayout';
import SocialLinkEditor from './components/SocialLinkEditor';
import CustomLinkEditor from './components/CustomLinkEditor';
import YesNoOptions from '@/constants/yes-no-options';
import { mapActions } from 'vuex';
import { MODULE_NAME as USER_MODULE, ACTIONS as USER_ACTIONS } from '@/store/modules/user/types';
import { MODULE_NAME as AUTH_MODULE, ACTIONS as AUTH_ACTIONS } from '@/store/modules/auth/types';
import Modal from '@/components/General/Modal';
import ProfileEditorPicture from '@/components/General/ProfileEditorPicture';

export default {
  name: 'ProfileEditor',

  data() {
    return {
      name: null,
      username: null,
      profileImageUrl: '',
      showEmailOnProfile: null,
      aboutMe: null,
      socialLinks: [],
      customLinks: [],
      socialLinksTouched: false,
      customLinksTouched: false,
      showModalOptions: false,
      modalPosition: 'center',
    };
  },

  components: { MainLayout, SocialLinkEditor, CustomLinkEditor, Modal, ProfileEditorPicture },

  computed: {
    yesAndNoOptions() {
      return YesNoOptions;
    },

    profileUrl() {
      return 'cpnow.co/' + this.username;
    },

    canShowModalOptions() {
      return this.showModalOptions;
    },
  },

  mounted() {
    this.getMyUserProfile();
  },

  methods: {
    ...mapActions(USER_MODULE, {
      updateMyUser: USER_ACTIONS.UPDATE_MY_USER,
      getMyUser: USER_ACTIONS.MY_USER,
    }),

    ...mapActions(AUTH_MODULE, {
      updateLoggedUserUsername: AUTH_ACTIONS.UPDATE_LOGGED_USER_USERNAME,
    }),

    async getMyUserProfile() {
      const user = await this.getMyUser();

      this.name = user.name;
      this.username = user.username;
      this.profileImageUrl = user.profileImageUrl;
      this.showEmailOnProfile = user.showEmailOnProfile;
      this.aboutMe = user.aboutMe;
      this.socialLinks = user.socialLinks;
      this.customLinks = user.customLinks;
    },

    onClickRemoveSocialNetwork(id) {
      this.socialLinks = this.socialLinks.filter((socialNetwork) => socialNetwork.id !== id);

      this.socialLinksTouched = true;
    },

    onClickRemoveCustomLink(id) {
      this.customLinks = this.customLinks.filter((customLink) => customLink.id !== id);

      this.customLinksTouched = true;
    },

    onClickAddSocialLink() {
      const totalSocialNetworks = this.socialLinks.length;

      if (totalSocialNetworks >= 30) {
        return;
      }

      const id = totalSocialNetworks + 1;

      this.socialLinks.push({
        id: id,
        name: null,
        link: null,
      });

      this.socialLinksTouched = true;
    },

    onClickAddCustomLink() {
      const totalCustomLinks = this.customLinks.length;

      if (totalCustomLinks >= 30) {
        return;
      }

      const id = totalCustomLinks + 1;

      this.customLinks.push({
        id: id,
        name: null,
        link: null,
      });

      this.customLinksTouched = true;
    },

    onChangeSocialNetwork(socialLink, sociaLinkId) {
      const socialLinkIndex = this.socialLinks.findIndex((socialNetwork) => socialNetwork.id === sociaLinkId);

      if (
        this.socialLinks[socialLinkIndex].name != socialLink.name ||
        this.socialLinks[socialLinkIndex].link != socialLink.link
      ) {
        this.socialLinksTouched = true;
      }

      this.socialLinks[socialLinkIndex].name = socialLink.name;
      this.socialLinks[socialLinkIndex].link = socialLink.link;
    },

    onChangeCustomLink(customLink, customLinkId) {
      const customLinkIndex = this.customLinks.findIndex((customLink) => customLink.id === customLinkId);

      if (
        this.customLinks[customLinkIndex].title != customLink.title ||
        this.customLinks[customLinkIndex].link != customLink.link
      ) {
        this.customLinksTouched = true;
      }

      this.customLinks[customLinkIndex].title = customLink.title;
      this.customLinks[customLinkIndex].link = customLink.link;
    },

    async onClickSave() {
      const name = this.name;
      const username = this.username;
      const showEmailOnProfile = this.showEmailOnProfile;
      const aboutMe = this.aboutMe;
      const socialLinks = this.parsedSocialNetworks();
      const customLinks = this.parsedCustomLinks();

      const user = {
        name,
        username,
        showEmailOnProfile,
        aboutMe,
        ...(this.socialLinksTouched && { socialLinks: socialLinks }),
        ...(this.customLinksTouched && { customLinks: customLinks }),
      };

      await this.updateMyUser(user);

      this.updateLoggedUserUsername(username);

      this.notifySuccess('Profile updated successfully!');

      this.socialLinksTouched = false;
      this.customLinksTouched = false;
    },

    parsedSocialNetworks() {
      const nonEmptySocialLinks = this.socialLinks.filter((socialLink) => {
        return socialLink.name && socialLink.link;
      });

      const socialLinks = nonEmptySocialLinks.map((socialLink, index) => ({
        order: index,
        name: String(socialLink.name).toLowerCase(),
        link: socialLink.link,
      }));

      return socialLinks;
    },

    parsedCustomLinks() {
      const nonEmptyCustomLinks = this.customLinks.filter((customLink) => {
        return customLink.title && customLink.link;
      });

      const customLink = nonEmptyCustomLinks.map((customLink, index) => ({
        order: index,
        title: customLink.title,
        link: customLink.link,
      }));

      return customLink;
    },

    onClickOptions() {
      this.showModalOptions = true;
    },

    onCloseModalOptions() {
      this.showModalOptions = false;
    },

    onSaveProfilePicture() {
      this.showModalOptions = false;
    },

    async onClickCopyLink() {
      const urlToCopy = 'cpnow.co/' + this.username;

      await navigator.clipboard.writeText(urlToCopy);

      this.notifyInfo();
    },
  },
};
