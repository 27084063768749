import Profile from '@/components/General/Profile';
import { MODULE_NAME as USER_MODULE, ACTIONS as USER_ACTIONS } from '@/store/modules/user/types';
import { mapActions } from 'vuex';
import { VueAvatar } from 'vue-avatar-editor-improved';
import waitForElement from '@/utils/wait-for-element';

export default {
  name: 'ProfileEditorPicture',

  components: {
    Profile,
    VueAvatar,
  },

  data() {
    return {
      newPicture: null,
      rotation: 0,
      scale: 1,
      vueAvatarWidth: 0,
      vueAvatarHeight: 400,
      vueAvatarBorder: 10,
      isNewPicture: false,
    };
  },

  async mounted() {
    const layoutMainElement = await waitForElement('.modal-body');

    // TODO: Explain this better
    this.vueAvatarWidth = layoutMainElement.offsetWidth - (this.vueAvatarBorder + this.vueAvatarBorder) - 40;
  },

  methods: {
    ...mapActions(USER_MODULE, {
      updateMyUserProfilePicture: USER_ACTIONS.UPDATE_MY_USER_PROFILE_PICTURE,
    }),

    dataURLtoBlob(dataURL) {
      const parts = dataURL.split(';base64,');
      const contentType = parts[0].split(':')[1];
      const raw = window.atob(parts[1]);
      const rawLength = raw.length;
      const uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }

      return new Blob([uInt8Array], { type: contentType });
    },

    async onClickSave() {
      const scaledImage = this.$refs.vueavatar.getImageScaled();
      const dataURL = scaledImage.toDataURL();
      const blob = this.dataURLtoBlob(dataURL);
      const file = new File([blob], 'my.png', { type: 'image/png' });

      this.picture = file;

      await this.updateMyUserProfilePicture(this.picture);

      this.notifySuccess('Profile picture updated');

      this.$emit('save');
    },

    onPictureReady() {
      this.scale = 1;
      this.rotation = 0;
      this.isNewPicture = true;
    },
  },
};
