import SocialLinksOptions from '@/constants/social-links-options';

export default {
  name: 'SocialLinkEditor',

  props: {
    socialLink: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      name: null,
      link: null,
      nullable: null,
    };
  },

  mounted() {
    this.name = this.socialLink.name;
    this.link = this.socialLink.link;
  },

  computed: {
    socialNetworksOptions() {
      return SocialLinksOptions;
    },
  },

  watch: {
    name() {
      this.emitChange();
    },

    link() {
      this.emitChange();
    },
  },

  methods: {
    onClickRemove() {
      this.$emit('remove');
    },

    emitChange() {
      this.$emit('change', {
        name: this.name,
        link: this.link,
      });
    },
  },
};
