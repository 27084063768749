export default [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Twitter',
    value: 'twitter',
  },
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'TikTok',
    value: 'tiktok',
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
  },
  {
    label: 'Snapchat',
    value: 'snapchat',
  },
  {
    label: 'Pinterest',
    value: 'pinterest',
  },
  {
    label: 'YouTube',
    value: 'youtube',
  },
  {
    label: 'Reddit',
    value: 'reddit',
  },
  {
    label: 'WhatsApp',
    value: 'whatsapp',
  },
  {
    label: 'Tumblr',
    value: 'tumblr',
  },
  {
    label: 'Pinterest',
    value: 'pinterest',
  },
  {
    label: 'GitHub',
    value: 'github',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
];
